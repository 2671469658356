import Vue from 'vue'
import Vuex from 'vuex'
import { authModule } from './auth/AuthStore'
import { authAdminModule } from './auth-admin/AuthAdminStore'
import { cityModule } from './cities/CityStore'
import { stateModule } from './states/StateStore'
import { adminModule } from './admin/AdminStore'
import { companyListModule } from './company-list/CompanyListStore'
import { orderModule } from './order/OrderStore'
import { productModule } from './product/ProductStore'
import { dialogModule } from './components/dialog/DialogStore'
import { systemModule } from './system/SystemStore'
import { regionModule } from './region/RegionStore'
import { addressModule } from './address/AddressStore'
import { companyModule } from './company/CompanyStore'
import { authCompanyModule } from './company/auth/AuthStore'
import { contactModule } from './contact-us/ContactStore'
import { agillitasModule } from './billing/AgillitasStore'
import { pagoparModule } from './billing/PagoparStore'
import { pixModule } from './billing/PixStore'
import { userModule } from './user/UserStore'
import { integrationModule } from './integration/IntegrationStore'
import { itemModule } from './item/ItemStore'
import { categoryModule } from './category/CategoryStore'
import { categoryIntegrationModule } from './category-integration/CategoryIntegrationStore'
import { billingModule } from './billing/BillingStore'
import { exchangeModule } from './exchange/ExchangeStore'
import { itemIntegrationModule } from './item-integration/ItemIntegrationStore'
import { manufacturersIntegrationModule } from './manufacturers-integration/ManufacturersIntegrationStore'
import { headerModule } from './header/HeaderStore'
import { manufacturersModule } from './manufacturers/ManufacturersStore'
import { shippingModule } from './shipping/ShippingStore'
import { accountModule } from './account/AccountStore'
import { freightModule } from './freight/FreightStore'
import { asaasModule } from './billing/AsaasStore'

Vue.use(Vuex)

const pspPix = process.env.VUE_APP_PSP_PIX || 'banco_brasil'

// temporario buscar tabela system
const ambientAgillitas = process.env.VUE_APP_AGILLITAS_PIX || 'homologation'
const enabledAgillitas = process.env.VUE_APP_AGILLITAS_PIX_ENABLED || 'false'

function verifyViewContext() {
  let viewContext =
    localStorage.getItem('viewContext') ||
    process.env.VUE_APP_INIT_VIEWCONTEXT ||
    'companyList'

  if (!localStorage.getItem('viewContext'))
    localStorage.setItem('viewContext', viewContext)

  return viewContext
}

const viewContext = verifyViewContext()

export default new Vuex.Store({
  state: {
    pspPix: pspPix,
    ambientAgillitas: ambientAgillitas,
    enabledAgillitas: enabledAgillitas,
    viewContext: viewContext,

    networkError: false,
    snackbar: {
      show: false,
      variant: null,
      message: null,
    },
  },
  mutations: {
    updateViewContext(state, setting) {
      state.viewContext = setting
      localStorage.setItem('viewContext', setting)
    },
    updateSnackbar(state, settings) {
      state.snackbar = {
        show: settings.show ?? true,
        variant: settings.variant ?? null,
        message: settings.message || '',
        duration: settings.duration || 3000,
        button: {
          label: (settings.button && settings.button.label) || null,
          link: (settings.button && settings.button.link) || null,
        },
      }
    },
  },
  actions: {
    updateSnackbar({ commit }, payload) {
      commit('updateSnackbar', payload)
    },
    updateViewContext({ commit }, payload) {
      commit('updateViewContext', payload)
    },
  },
  modules: {
    authModule,
    authAdminModule,
    adminModule,
    companyListModule,
    productModule,
    orderModule,
    dialogModule,
    systemModule,
    regionModule,
    companyModule,
    addressModule,
    authCompanyModule,
    contactModule,
    agillitasModule,
    pagoparModule,
    pixModule,
    userModule,
    integrationModule,
    itemModule,
    categoryModule,
    categoryIntegrationModule,
    billingModule,
    exchangeModule,
    itemIntegrationModule,
    manufacturersIntegrationModule,
    headerModule,
    manufacturersModule,
    shippingModule,
    accountModule,
    cityModule,
    stateModule,
    freightModule,
    asaasModule,
  },
})
