import service from '@/services/category-integration/CategoryIntegrationService'

const selectedCategoriesInIntegration = localStorage.getItem(
  'selectedCategoriesInIntegration'
)
let selectedCategories = selectedCategoriesInIntegration
  ? JSON.parse(selectedCategoriesInIntegration)
  : []

export const categoryIntegrationModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    categoriesIntegrationList: [],
    noMoreCategories: false,
    selectedCategories,
  },
  mutations: {
    ADD_SELECTED_CATEGORIES(state, payload) {
      const mappedPayload = payload.map((category) => {
        const obj = {
          ID: category,
        }
        const exist = state.selectedCategories.find((i) => i.ID == category)
        return exist || obj
      })

      state.selectedCategories = mappedPayload
      localStorage.setItem(
        'selectedCategoriesInIntegration',
        JSON.stringify(state.selectedCategories)
      )
    },

    CLEAR_SELECTED_CATEGORIES(state) {
      state.selectedCategories = []
      localStorage.setItem(
        'selectedCategoriesInIntegration',
        JSON.stringify(state.selectedCategories)
      )
    },

    SET_CATEGORIES_INTEGRATION_LIST(state, payload) {
      if (payload.forward) {
        const newCategory = payload.data
        const uniqueCategories = newCategory.filter((newCategoryObject) => {
          return !state.categoriesIntegrationList.some((existingCategory) => {
            return existingCategory.ID === newCategoryObject.ID
          })
        })

        state.categoriesIntegrationList.push(...uniqueCategories)
      } else state.categoriesIntegrationList = payload.data ?? payload

      if (payload.ignoreFatherIfIsNotOnList) {
        state.categoriesIntegrationList = state.categoriesIntegrationList.map(
          (e) => {
            if (e.PARENT_ID) {
              if (
                !state.categoriesIntegrationList.some(
                  (category) => category.ID == e.PARENT_ID
                )
              ) {
                e.PARENT_ID = ''
              }
            }
            return { ...e }
          }
        )
      }
    },
    SET_NO_MORE_CATEGORIES(state, payload) {
      state.noMoreCategories = payload
    },
    CLEAR_CATEGORIES_INTEGRATION_LIST(state) {
      state.categoriesIntegrationList = []
    },
    ADD_MORE_CATEGORIES(state, payload) {
      state.categoriesIntegrationList.push(...payload)
    },
  },
  actions: {
    async getCategoriesIntegrationAPI({ commit, rootState, state }, q) {
      try {
        let query = { ...q }
        query.integracao =
          rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA
        let response = await service.getCategoriesIntegration(query)
        response.forward = false

        if (response.last_page == query.page) {
          commit('SET_NO_MORE_CATEGORIES', true)
        }
        if (state.categoriesIntegrationList.length > 0) response.forward = true
        if (query.filters) response.ignoreFatherIfIsNotOnList = true
        commit('SET_CATEGORIES_INTEGRATION_LIST', response)
      } finally {
      }
    },

    setCategoriesList({ commit }, payload) {
      commit('SET_CATEGORIES_INTEGRATION_LIST', payload)
    },

    clearSelectedCategories({ commit }) {
      commit('CLEAR_SELECTED_CATEGORIES')
    },

    setSelectedCategories({ commit }, payload) {
      commit('ADD_SELECTED_CATEGORIES', payload)
    },

    addMoreCategories({ commit }, payload) {
      commit('ADD_MORE_CATEGORIES', payload)
    },

    clearCategoriesIntegrationList({ commit }) {
      commit('CLEAR_CATEGORIES_INTEGRATION_LIST')
    },

    setNoMoreCategories({ commit }, payload) {
      commit('SET_NO_MORE_CATEGORIES', payload)
    },

    async getCategoryIntegrationAPI({ rootState }, id) {
      try {
        let query = {}
        query.integracao =
          rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA
        let data = await service.getCategoryIntegrationById(id, query)
        return data
      } catch (error) {
        console.error(error)
      }
    },
    async insertCategoriesIntegration({ rootState }, payload) {
      const response = await service.insertCategoriesIntegration({
        data: payload,
        integracao: rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA,
      })
      return response
    },
    async deleteCategoryIntegration({ dispatch, rootState }, id) {
      const query = {
        integracao: rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA,
      }
      const status = await service.deleteCategoryIntegration(id, query)
      return status
    },

    async deleteArrayCategoriesIntegration({ rootState }, ids) {
      const query = {
        integracao: rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA,
      }
      const status = await service.deleteArrayCategoriesIntegration(ids, query)
      console.log(status)
    },

    async getAvailableCategories({ rootState }, payload) {
      let query = { ...payload }
      query.integracao = rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA
      const { data } = await service.getCategoriesIntegration(query)
      return data
    },

    async transformSelectedCategoriesInIntegrationAndUpdate(
      { state, rootState },
      payload
    ) {
      if (!state.selectedCategories.length) return
      const categories = state.selectedCategories.map((category) => {
        return {
          IDINTEGRACAO: category.ID,
          ...payload,
        }
      })
      const response = await service.insertCategoriesIntegration({
        data: categories,
        integracao: rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA,
      })
      return response
    },
  },
  getters: {
    noMoreCategories: (state) => state.noMoreCategories,
    getSelectedCategories: (state) => state.selectedCategories.map((e) => e.ID),
  },
}
