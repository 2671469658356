import Service from '../Service'
class ManufacturersIntegrationService extends Service {
  constructor() {
    super()
    this.resource = 'mbcommerce/manufacturers'
  }

  async getManufacturers(query = null) {
    let requestUrl = this.createRequestUrl(query, this.resource)
    return await this.getAPI(requestUrl)
  }

  async insertManufacturers(payload) {
    return await this.insert(payload)
  }

  async getImage(query = null, payload) {
    let requestUrl = `/${this.resource}/getImage/${payload}`
    requestUrl = this.createRequestUrl(query, requestUrl)
    return await this.getAPI(requestUrl)
  }
}

export default new ManufacturersIntegrationService()
