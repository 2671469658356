import service from '@/services/product/ProductService'
import { verifyPropertyNameByContext, getListFromStorage } from '@/utils/utils'

const CART_PROPERTY = 'cart'
const WISHLIST_PROPERTY = 'wish'

let localCart = getListFromStorage(verifyPropertyNameByContext(CART_PROPERTY))
let localWishList = getListFromStorage(
  verifyPropertyNameByContext(WISHLIST_PROPERTY)
)

let localProductsFilters = localStorage.getItem('productsFilters')
  ? JSON.parse(localStorage.getItem('productsFilters'))
  : {}

function setWishListOnStorage(wishlist) {
  let newlist = wishlist.map((v) => {
    return {
      id: v.id,
    }
  })

  localStorage.setItem(
    verifyPropertyNameByContext(WISHLIST_PROPERTY),
    JSON.stringify(newlist)
  )
}

function setCartListOnStorage(cartList) {
  let newlist = cartList.map((v) => {
    return {
      id: v.id,
      quantity: v.quantity,
      stock: v.stock,
    }
  })

  localStorage.setItem(
    verifyPropertyNameByContext(CART_PROPERTY),
    JSON.stringify(newlist)
  )
}

export const productModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    product: null,
    productList: [],
    notHome: false,
    tmpProductList: [],
    isFilteringCategoryOnCompanyList: false,
    filteredProductList: [],
    loadingItems: false,
    pagination: {
      page: 1,
      current_page: 1,
      last_page: 1,
      per_page: 32,
      to: 0,
      total: 0,
    },
    header: [],
    filterPrice: [],
    filter: {
      price: '',
      marca: '',
      searchText: '',
      categorie: [],
      orderby: 0,
    },
    cart: localCart,
    errorInCheckout: {
      measureOrWeightIsNull: null,
      rgIsNull: null,
      productPriceEqualToZero: null,
    },
    wishList: localWishList,
    productsFilters: localProductsFilters,
    cartProducts: [],
    subCategories: [],
    categories: null,
    countCategories: null,
    showAllCategory: null,
    mainCategory: null,
    categoryOpenedIndex: null,
  },
  mutations: {
    SET_PAGINATION(state, payload) {
      state.pagination.current_page = parseInt(payload.current_page)
      state.pagination.page = parseInt(payload.current_page)
      state.pagination.last_page = parseInt(payload.last_page)
      state.pagination.per_page = parseInt(payload.per_page)
      state.pagination.total = parseInt(payload.total)
    },

    SET_ISFILTERING_CATEGORY_COMPANY_LIST(state, payload) {
      state.isFilteringCategoryOnCompanyList = payload
    },

    SET_OPENDED_INDEX_CATEGORY(state, payload) {
      state.categoryOpenedIndex = payload
    },

    SET_SHOWALL_CATEGORY(state, payload) {
      // seta a categoria escolhida para ser detalhada
      state.showAllCategory = payload
      if (payload != null) {
        let saveP = {
          title: payload.title,
          base: payload.base,
          root: payload.root,
          rootIndex: payload.rootIndex,
        }
        localStorage.setItem('showCategory', JSON.stringify(saveP))
      } else {
        localStorage.removeItem('showCategory')
      }
    },

    SET_MAIN_CATEGORY(state, payload) {
      // seta a categoria principal para facilitar na hora de detalhar via subNivel
      state.mainCategory = payload
    },

    SET_SUBCATEGORIES_LIST(state, payload) {
      state.subCategories = payload ?? []
    },

    SET_CATEGORIES_LIST(state, payload) {
      state.categories = payload ?? []
      for (let index = 0; index < state.categories.length; index++) {
        state.categories[index].rootIndex = index
      }
      state.countCategories = state.categories.length
    },

    SET_PRICES_LIST(state, payload) {
      if (!payload) return []
      const prices = payload.map((ele) => {
        return {
          ...ele,
          DESCRICAO: ele.DESCRICAO.slice(2).trim() + '(' + ele.QTDITENS + ')',
          VALOR: ele.VALOR.trim(),
        }
      })

      const qtdTotal = prices.reduce((val, p) => {
        return (val += p.QTDITENS)
      }, 0)

      state.filterPrice = [
        { DESCRICAO: 'Todos', QTDITENS: qtdTotal, VALOR: 0 },
      ].concat(prices)
    },

    SET_PRODUCT_LIST(state, payload) {
      const currencies = this.getters['systemModule/getSystemCurrencies']
      const currencySystem = this.getters['systemModule/getMainCurrency']
      const currentCurrency = currencies.find((c) => c.index == currencySystem)
      const items = payload
        ? payload.map((product) => {
            if (product.itens) {
              product = {
                ...product.itens,
                ...product,
              }
              delete product.itens
            }

            let photoList = []
            if (product.itens_complemento) {
              let obj = product.itens_complemento
              Object.keys(obj).forEach((field) => {
                if (field.startsWith('IMAGEM') && obj[field])
                  photoList.push('data:image/jpeg;base64,' + obj[field])
              })
            }

            let obj = {
              id: product.CODIGO,
              name:
                product.DESCRICAOINTERNET &&
                product.DESCRICAOINTERNET.length > 0
                  ? product.DESCRICAOINTERNET
                  : product.DESCRICAO,
              category: product ? product.MARCA : null,
              subCategory: product ? product.GRUPO : null,
              defaultPrice: parseFloat(product.PRECOVAREJO ?? 0).toFixed(2),
              priceItem: product.PRECOITEM,
              price: product.PRECOITEM
                ? parseFloat(product.PRECOITEM[currentCurrency.cifrao]).toFixed(
                    2
                  )
                : parseFloat(product.PRECOVAREJO ?? 0).toFixed(2),
              offerPrice: product.OFERTAITEM
                ? parseFloat(
                    product.OFERTAITEM[currentCurrency.cifrao] ??
                      product.OFERTAITEM ??
                      0
                  ).toFixed(2)
                : '0.00',

              isOffer: false,
              specifications: product.itens_complemento
                ? product.itens_complemento.ESPECIFICACOES
                : 'Não existem especificações para este produto',
              specificationsHtml: product.itens_complemento
                ? product.itens_complemento.ESPECIFICACOESHTML
                : null,
              image: photoList.length > 0 ? photoList[0] : null,
              photoList: photoList,
              stock: parseInt(product.SALDOITEM),
              equivalency: product.EQUIVALENCIA ?? null,
              size: product.TAMANHO ?? null,
              color: product.COLOR ?? null,
              composite: product.ITEMCOMPOSTO ?? 'N',
              categoria: product.CATEGORIA ?? null,
              categoria1: product.CATEGORIA1 ?? null,
              categoria2: product.CATEGORIA2 ?? null,
              categoria3: product.CATEGORIA3 ?? null,
            }
            // alteração Brasguay - oferta aumenta preço dos produtos
            obj.isOffer =
              parseFloat(obj.offerPrice) > 0 &&
              parseFloat(obj.offerPrice) < parseFloat(obj.price)

            obj.price =
              parseFloat(obj.offerPrice) > 0 &&
              parseFloat(obj.offerPrice) > parseFloat(obj.price)
                ? obj.offerPrice
                : obj.price
            // PROPERTIES FROM COMPANYLIST
            obj.quantity = product.QUANTIDADE ? parseInt(product.QUANTIDADE) : 0
            obj.processed = product.QUANTIDADEPROC
              ? parseInt(product.QUANTIDADEPROC)
              : 0
            obj.avaiable = obj.quantity - obj.processed
            return obj
          })
        : []

      state.productList = items.filter((i) => i.stock > 0 || i.composite == 'S')
    },

    SET_PRODUCT(state, payload) {
      state.product = payload.data
    },

    SET_LOADING(state) {
      state.loadingItems = !state.loadingItems
    },

    SET_ERRORINCHECKOUT(state, payload) {
      state.errorInCheckout = {
        ...state.errorInCheckout,
        ...payload,
      }
    },

    ADD_ITEM_TO_CART(state, payload) {
      const { id, quantity, composite } = payload

      let product = state.productList.filter((v) => v.id == id)[0]
      if (!product) product = state.cartProducts.filter((v) => v.id == id)[0]
      if (payload.stock >= quantity || composite == 'S') {
        const idx = state.cart.findIndex((product) => {
          return product.id === id
        })

        if (idx === -1) {
          state.cart.push({
            ...payload,
          })
        } else if (
          payload.stock >=
            parseInt(state.cart[idx].quantity) + parseInt(quantity) ||
          composite == 'S'
        ) {
          state.cart[idx].quantity += parseInt(quantity)
          state.cart[idx].stock = payload.stock
        } else {
          // para atualizar o carrinho conforme tem no saldo de estoque
          state.cart[idx].stock = payload.stock
          state.cart[idx].quantity = parseInt(payload.stock)
          setCartListOnStorage(state.cart)

          throw 'Atingiu a quantidade máxima disponível em estoque'
        }

        setCartListOnStorage(state.cart)
      } else {
        throw 'Não possui estoque'
      }
    },

    SET_WISHLIST(state, payload) {
      let newlist = payload.map((v) => {
        if (v.photoList) {
          var photolist = []

          v.photoList.forEach((photo) => {
            if (photo && photo.length > 0) photolist.push(photo)
          })
        }

        return {
          ...v,
          image: v.image && v.image.length > 0 ? v.image : '',
          photoList: photolist,
        }
      })
      state.wishList = newlist
    },

    ADD_ITEM_TO_WISHLIST(state, payload) {
      const { id } = payload

      const idx = state.wishList.findIndex((wish) => {
        return wish.id === id
      })

      if (idx === -1) {
        state.wishList.push({
          ...payload,
        })
      }

      setWishListOnStorage(state.wishList)
    },

    REMOVE_ITEM_FROM_WISHLIST(state, payload) {
      const { id } = payload
      state.wishList = state.wishList.filter((product) => {
        return product.id !== id
      })
      setWishListOnStorage(state.wishList)
    },

    REMOVE_ITEM_FROM_CART(state, payload) {
      const { id } = payload
      const idx = state.cart.findIndex((product) => {
        return product.id === id
      })
      if (idx != -1 && parseInt(state.cart[idx].quantity) - 1 > 0)
        state.cart[idx].quantity -= 1
      else state.cart = state.cart.filter((item) => item.id !== id)

      localStorage.setItem(
        verifyPropertyNameByContext(CART_PROPERTY),
        JSON.stringify(state.cart)
      )
    },

    async UPDATE_FILTER(state, payload) {
      if (!payload) return
      Object.keys(payload).forEach((e) => {
        if (e == 'price') {
          const price =
            payload.price && payload.price.VALOR
              ? payload.price.VALOR
              : payload.price

          state.productsFilters = {
            ...state.productsFilters,
            price: price,
          }

          localStorage.setItem(
            'priceFiltersSelected',
            JSON.stringify(payload.price)
          )
        } else {
          state.productsFilters[e] = payload[e]
        }
      })

      state.productsFilters.MOEDA = this.getters['systemModule/getMainCurrency']
      state.productsFilters.page = payload.page
        ? payload.page
        : state.productsFilters.page ?? 1
      state.productsFilters.per_page = state.pagination.per_page
      state.productsFilters = {
        ...state.productsFilters,
        marca: state.productsFilters.marca ?? '',
        HIBRIDO: state.productsFilters.HIBRIDO ?? '',
        categories: state.productsFilters.categories,
      }
      if (!state.productsFilters.categories) {
        localStorage.removeItem('showCategory')
      }
      localStorage.setItem(
        'productsFilters',
        JSON.stringify(state.productsFilters)
      )
    },

    /** RECUPERAR AMBAS LISTAS CARRINHO E FAVORITOS */
    RETRIEVE_LOCALSTORAGE_CART(state) {
      state.cart = getListFromStorage(
        verifyPropertyNameByContext(CART_PROPERTY)
      )
      state.wishList = getListFromStorage(
        verifyPropertyNameByContext(WISHLIST_PROPERTY)
      )
    },

    UPDATE_PRODUCTS_CART(state, payload) {
      state.cartProducts = payload
    },

    CLEAR_PRODUCTS_CART(state) {
      state.cartProducts = []
    },

    REMOVE_SEARCHTEXT(state) {
      state.filter.searchText = ''
      localStorage.setItem('searchTextProducts', state.filter.searchText)
    },

    CLEAR_CART(state, isDeepClear) {
      state.cart = []
      if (isDeepClear)
        localStorage.removeItem(verifyPropertyNameByContext(CART_PROPERTY))
    },

    CLEAR_WISHLIST(state, isDeepClear) {
      state.wishList = []
      if (isDeepClear)
        localStorage.removeItem(verifyPropertyNameByContext(WISHLIST_PROPERTY))
    },

    UPDATE_SEARCHTEXT(state, payload) {
      state.filter.searchText = payload
    },

    CHANGE_PRODUCTS_FILTER_BY_CATEGORIES(state, payload) {
      state.productList = payload.itens
      state.productsFilters.categories = payload.rootObj
    },

    SET_TMP_PRODUCTLIST(state, payload) {
      state.tmpProductList = payload
    },
    SET_NOT_HOME(state, payload) {
      state.notHome = payload
    },
  },
  actions: {
    async addItemToCart({ dispatch, commit }, payload) {
      const { quantity } = payload
      delete payload.quantity
      const item = await dispatch('getItemStock', { ...payload })
      commit('ADD_ITEM_TO_CART', {
        ...item.itens,
        quantity: parseInt(quantity),
      })
    },

    async getSuggestions(context, payload) {
      const { data } = await service.getSuggestions(payload)
      return data
    },

    setNotHome({ commit }, payload) {
      commit('SET_NOT_HOME', payload)
    },

    async getItemStock(context, payload) {
      const { data } = await service.getItemsStock(payload)
      return data
    },

    removeItemFromCart({ commit }, payload) {
      commit('REMOVE_ITEM_FROM_CART', payload)
    },

    setOpenedCategoryIndex({ commit }, payload) {
      commit('SET_OPENDED_INDEX_CATEGORY', payload)
    },

    setShowllAllCategory({ commit }, payload) {
      commit('SET_SHOWALL_CATEGORY', payload)
    },

    async setSearchCategory({ commit, dispatch }, payload) {
      commit('SET_SHOWALL_CATEGORY', null)
      await dispatch('updateFilter', payload)
      await dispatch('getProducts')
    },

    backToRootShowAllCategory({ commit, getters }) {
      let payload = {
        itens: getters.getMainCategory,
        title: getters.getMainCategory.TITLE,
        rootIndex: 0,
        base: true,
        root: true,
        objRoot: getters.showAllCategory.objRoot,
      }
      let tmp = payload
      for (
        let index = 0;
        index < Object.keys(tmp.itens.CHILDS).length;
        index++
      ) {
        tmp.itens.CHILDS[index].rootIndex = index
      }
      commit('SET_SHOWALL_CATEGORY', tmp)
    },

    setMainCategory({ commit }, payload) {
      commit('SET_MAIN_CATEGORY', payload)
    },

    async getByCategory({ dispatch }, payload) {
      await dispatch('updateFilter', payload)
      await dispatch('getProducts')
    },

    updateFilter({ commit }, payload) {
      commit('UPDATE_FILTER', payload)
    },

    clearCart({ commit }, isDeepClear) {
      commit('CLEAR_CART', isDeepClear)
      commit('CLEAR_PRODUCTS_CART')
    },

    clearWishList({ commit }, isDeepClear = false) {
      commit('CLEAR_WISHLIST', isDeepClear)
    },

    async retrieveCart({ state, commit, dispatch }) {
      commit('RETRIEVE_LOCALSTORAGE_CART')
      if (state.cart && state.cart.length > 0) await dispatch('updateCartItems')
    },

    async getItems(context, payload) {
      let items = []

      payload.forEach((item) => {
        if (item.id) items.push({ id: item.id })
        else items.push(item)
      })
      const seqCompanyList =
        context.rootState.companyListModule.companyList?.SEQUENCIAL
      if (seqCompanyList) items.push({ companyList: true })

      items.push({ moeda: context.rootGetters['systemModule/getMainCurrency'] })
      const { data } = await service.getItems(items)
      return data
    },

    async updateCartItems({ dispatch, commit, state }) {
      let payload = Object.assign([], state.cart)
      payload.push({ estoque: true })

      const data = await dispatch('getItems', payload)
      commit('UPDATE_PRODUCTS_CART', data.itens)
    },

    /**
     * LISTA DE DESEJOS
     */

    addItemToWishList({ commit }, payload) {
      commit('ADD_ITEM_TO_WISHLIST', payload)
    },

    removeItemFromWishList({ commit }, payload) {
      commit('REMOVE_ITEM_FROM_WISHLIST', payload)
    },

    setWishList({ commit }, payload) {
      commit('SET_WISHLIST', payload)
    },

    removeSearchText({ commit }) {
      commit('REMOVE_SEARCHTEXT')
    },

    /**
     *  Get Product List by filter from backend
     */
    async getProducts({ state, commit }) {
      try {
        commit('SET_LOADING')

        const response = await service.getProducts(state.productsFilters)

        commit('SET_PRODUCT_LIST', response.data)
        commit('SET_SUBCATEGORIES_LIST', response.marcas)
        commit('SET_CATEGORIES_LIST', response.categorias)
        commit('SET_PRICES_LIST', response.precos)
        commit('SET_PAGINATION', response)
      } finally {
        commit('SET_LOADING')
      }
    },

    async getProduct({ commit }, payload) {
      const response = await service.getProduct(payload)
      commit('SET_PRODUCT', response)
    },

    /**
     *  Expect a List of Products from others contexts to set
     */
    async setProductList({ commit, state }, payload) {
      if (this.state['viewContext'] == 'companyList') {
        commit('SET_CATEGORIES_LIST', payload.categorias)
      }
      const formatPayload =
        payload && payload.hasOwnProperty('items') ? payload.items : payload
      commit('SET_PRODUCT_LIST', formatPayload)
    },

    async setSearchText({ commit }, payload) {
      commit('UPDATE_SEARCHTEXT', payload)
    },

    async changeProductsFilterByCategories({ commit, state }, payload) {
      // only for company list
      //console.log(JSON.stringify(payload))

      if (!state.isFilteringCategoryOnCompanyList) {
        commit('SET_ISFILTERING_CATEGORY_COMPANY_LIST', true)
        await commit('SET_TMP_PRODUCTLIST', state.productList)
      }

      const objRoot = payload?.objRoot ?? payload

      let products = state.tmpProductList.filter(
        (product) => product.categoria == objRoot?.TITLE
      )

      if (objRoot.TITLE1) {
        products = products.filter((p) => p.categoria1 == objRoot?.TITLE1)
      }
      if (objRoot.TITLE2) {
        products = products.filter((p) => p.categoria2 == objRoot?.TITLE2)
      }
      if (objRoot.TITLE3) {
        products = products.filter((p) => p.categoria3 == objRoot?.TITLE3)
      }

      const nwPayload = {
        rootObj: JSON.stringify(objRoot),
        itens: products,
      }

      await commit('CHANGE_PRODUCTS_FILTER_BY_CATEGORIES', nwPayload)
    },

    async setErrorInCheckout({ commit }, payload) {
      commit('SET_ERRORINCHECKOUT', payload)
    },
  },
  getters: {
    loadingItems: (state) => {
      return state.loadingItems
    },

    choosenCategories: (state) => {
      if (state.productsFilters.categories != undefined) {
        return JSON.parse(state.productsFilters.categories)
      } else {
        return null
      }
    },

    openedCategoryIndex: (state) => {
      return state.categoryOpenedIndex
    },

    filteredProductList: (state) => {
      return state.filteredProductList
    },
    getMainCategory: (state) => {
      return state.mainCategory
    },

    products: (state) => {
      return state.productList
    },

    showAllCategory: (state) => {
      return state.showAllCategory
    },

    subCategories: (state) => {
      const qtdTotal = state.subCategories.reduce((val, sub) => {
        return (val += sub.QTDITENS)
      }, 0)
      return [{ MARCA: 'Todos', QTDITENS: qtdTotal }].concat(
        state.subCategories.sort()
      )
    },

    categories: (state) => {
      return state.categories
    },

    wishList: (state) => {
      return state.wishList
    },

    cart: (state) => {
      return state.cart
    },

    cartBillingDetail: (state, getters, rootState, rootGetters) => {
      const regionTax = rootGetters['regionModule/regionTax']

      let cart =
        getters.cartDetail.length > 0
          ? getters.cartDetail.map((item) => {
              return {
                qtd: parseInt(item.quantity),
                value:
                  item?.offerPrice && item?.offerPrice > 0
                    ? item.offerPrice
                    : item.price,
              }
            })
          : []

      if (regionTax?.price > 0) cart.push({ qtd: 1, value: regionTax?.price })
      return cart
    },

    cartDetail: (state) => {
      let cart = []
      if (state.cart && state.cart.length > 0) {
        cart = state.cart.map((item) => {
          const product = state.cartProducts.filter(
            (product) => item.id === product.id
          )[0]
          if (!product) return { ...item }
          return {
            ...item,
            price: product.price,
            priceItem: product.priceItem,
            offerPrice: product.offerPrice,
            isOffer: product.isOffer,
            image: product?.photoList ? product.photoList[0] : '',
            name: product.name,
            stock: product.stock,
            avaiable: product.avaiable,
            processed: product.processed,
            composite: product.composite,
            quantity: parseInt(item.quantity),
            medida: {
              altura: product.medida?.altura ? product.medida.altura : null,
              largura: product.medida?.largura ? product.medida.largura : null,
              profundidade: product.medida?.profundidade
                ? product.medida.profundidade
                : null,
            },
            peso: product.peso,
          }
        })
      }
      return cart ?? []
    },

    totalCart: (state, getters) => {
      return getters.cartDetail.length > 0
        ? getters.cartDetail.reduce((total, item) => {
            let soma = 0
            if (
              item &&
              ((item.stock > 0 &&
                parseInt(item.quantity) <= parseInt(item.stock)) ||
                item.composite == 'S')
            ) {
              soma =
                parseInt(item.quantity) *
                (item.isOffer
                  ? parseFloat(item.offerPrice)
                  : parseFloat(item.price))
            }
            return (total += soma)
          }, 0.0)
        : 0
    },

    soldOutItems: (state, getters) => {
      const items =
        getters.cartDetail.length > 0
          ? getters.cartDetail.filter(
              (product) =>
                (product.stock <= 0 || product.quantity > product.stock) &&
                product.composite != 'S'
            )
          : []
      return items
    },
    getExistErrorInCheckout() {
      return state.errorInCheckout
    },

    percentOfferProduct() {
      return (product) => {
        return (
          (parseFloat(product.offerPrice) / parseFloat(product.price) - 1) *
          100 *
          -1
        ).toFixed(0)
      }
    },

    soldOutProduct() {
      return (product) => {
        const realQnt = product.quantity - product.processed
        return (
          ((product && product.stock <= 0) || realQnt > product.stock) &&
          product.composite != 'S'
        )
      }
    },

    searchText: (state) => {
      return state.filter.searchText
    },

    errorInCheckout: (state) => {
      return state.errorInCheckout
    },

    countCategories: (state) => {
      return state.countCategories
    },
  },
}
