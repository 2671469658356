import Service from '@/services/Service'

class PixService extends Service {
  constructor() {
    super('PIX')
    this.resource = 'mbcommerce/billing'
  }

  createRequestUrl(query) {
    let requestUrl = `${this.resource}?`

    if (query) query = this.encodeQueryValues(query)

    if (query.todos)
      requestUrl += this.hasParameter(requestUrl)
        ? `&TODOS=${query.todos}`
        : `TODOS=${query.todos}`
    else
      requestUrl += this.hasParameter(requestUrl)
        ? `&page=${query.page}`
        : `page=${query.page}`

    if (query.filtro)
      requestUrl += this.hasParameter(requestUrl)
        ? `&filtro=${query.filtro}`
        : `filtro=${query.filtro}`
    return requestUrl
  }

  async getPayment(payload) {
    let requestUrl = `${this.resource}/pix/${encodeURIComponent(payload)}`
    return await this.getAPI(requestUrl)
  }

  // COBRANCAS
  async sendPaymentConfirmation(payload) {
    const url = this.resourceBilling + '/payment-confirmation?txId=' + payload
    return await this.getAPI(url)
  }
}

export default new PixService()
