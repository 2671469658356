import service from '@/services/manufacturers-integration/ManufacturersIntegrationService'

export const manufacturersIntegrationModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {},
  mutations: {},
  actions: {
    async getManufacturersIntegration({ rootState }, q) {
      let query = { ...q }
      query.hibrido = query.hibrido ?? ''
      query.integracao = rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA
      let response = await service.getManufacturers(query)
      return response
    },
    async insertManufacturersIntegration({ rootState, dispatch }, payload) {
      try {
        payload.integracao =
          rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA

        const response = await service.insertManufacturers(payload)

        if (payload.data?.length == 1) {
          await dispatch(
            'manufacturersModule/setImageForManufacturer',
            {
              NOME: payload.data[0].NOME,
              IMAGEM: payload.image,
              INTEGRADO: 'S',
            },
            {
              root: true,
            }
          )
        } else {
          await dispatch(
            'manufacturersModule/setIntegrationForArray',
            payload.data,
            {
              root: true,
            }
          )
        }
        return response
      } catch (e) {
      
        return e
      }
    },
    async getImage({ rootState, dispatch }, payload) {
      const query = {
        integracao: rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA,
      }
      const data = await service.getImage(query, payload)
      await dispatch(
        'manufacturersModule/setImageForManufacturer',
        { NOME: payload, IMAGEM: data, INTEGRADO: 'S' },
        {
          root: true,
        }
      )
      return data
    },
  },
}
