const integrationsTutorialTexts = {
  MCommerce: {
    title: 'MCommerce',
    steps: [
      {
        title: 'O que é o MCommerce?',
        description:
          'O MCommerce é a base fundamental para o funcionamento do E-commerce. Por meio dele, é possível definir os itens, categorias, fabricantes e opções de frete local que serão utilizados na plataforma de comércio online. Além disso, possibilita a personalização das cores do e-commerce e a adição de novos serviços e formas de pagamento.',
      },
      {
        title: 'Campos bases para o MCommerce',
        description:
          'Para que o MCommerce funcione corretamente, é necessário preencher os campos obrigatórios, como: <br/> <b class="font-weight-bold">Tipo Preço</b>: Referente ao tipo de preço que o Mcommerce vai assumir ao trazer os itens do gestão, exemplo: Preço varejo, Preço especial e etc. <br/> <b class="font-weight-bold">Moeda<b/>: Referente a moeda que será utilizada pelo Mcommerce, exemplo: Real, Dólar e etc.<br/><b class="font-weight-bold">Usuários para Mensagem<b/>: Referente aos usuários do gestão que receberão notificações de novos pedidos realizados no e-commerce. <br/><b class="font-weight-bold">Item taxa de entrega<b/>: Referente ao item do gestão que será utilizado como item genérico para a taxa de entrega. Deve ser do tipo serviço e sem um valor inicial. <br/> <b class="font-weight-bold">Converte medidas para cm<b/>: Caso esteja marcado, o Mcommerce irá converter as medidas dos itens do gestão para centímetros. (<b class="font-weight-medium">Obs<b/>: esse campo é visível caso você tenha alguma integração de frete). <br/> <b class="font-weight-bold">Converte peso em gramas<b/>: Caso esteja marcado, o Mcommerce irá converter o peso dos itens do gestão para gramas. (<b class="font-weight-medium">Obs<b/>: esse campo é visível caso você tenha alguma integração de frete).',
      },
      {
        title: 'Personalização de cores',
        description:
          'A personalização de cores do MCommerce permite que você defina as cores principais do seu e-commerce. Para isso, basta preencher os campos com as cores desejadas, seguindo o padrão hexadecimal. Atualmente apenas o tema principal está sendo utilizado no e-commerce, mas em breve será possível escolher entre o tema principal e alternativo.',
      },
      {
        title: 'Explicação dos campos de cores',
        description: 'Teste...',
      },
    ],
  },
  frenet: {
    title: 'Frenet',
    steps: [
      {
        title: 'Integração Frenet',
        subtitle: 'Como integrar a Frenet ao seu e-commerce?',
        description:
          'Para integrar a Frenet ao seu e-commerce, é necessário possuir uma conta na Frenet, com um token de acesso. Com essas informações em mãos, basta acessar a integração e preencher os campos solicitados.',
      },
      {
        title: 'Campos obrigatórios',
        subtitle:
          'Quais são os campos obrigatórios nos itens para a integração com a Frenet?',
        description:
          'Alem das informações base do item, ele deverá conter o campo de <b class="font-weight-bold">medida<b/> e o de <b class="font-weight-bold">peso<b/> preenchidos, sendo que a medida precisa estar no padrão: AxLxP, exemplo: 10x10x10.',
      },
    ],
  },
  pixBb: {
    title: 'Pix Banco do Brasil',
    steps: [
      {
        title: 'Integração Pix Banco do Brasil',
        subtitle:
          'O que é necessário para integrar o pix BB ao seu e-commerce?',
        description:
          'Para integrar o Pix do Banco do Brasil ao seu e-commerce de forma eficiente, é fundamental que você possua uma conta jurídica ativa no Banco do Brasil, onde a API do Pix esteja habilitada. Além disso, é necessário realizar um cadastro no portal developers BB para acessar os recursos necessários. Ao ativar a API, serão disponibilizados elementos essenciais como o client_id e client_secret, bem como sua chave de desenvolvimento. Em casos em que a versão da API é superior a 2.0, você também terá acesso à sua chave privada e certificado. Essas informações são cruciais para garantir a segurança e o funcionamento adequado da integração.',
      },
      {
        title: 'Relação entre campos',
        subtitle:
          'Como os campos da integração se relacionam com meus dados do pix BB?',
        description:
          'Campos:<ul> <li> Usuario = client_id <li/> <li>Senha = client_secret<li/><li>Chave de Desenvolvimento = developer_application_key<li/><ul/>',
      },
    ],
  },
  pixSc: {
    title: 'Pix Sicredi',
    steps: [
      {
        title: 'Integração Pix Sicredi',
        subtitle:
          'O que é necessário para integrar o pix Sicredi ao seu e-commerce?',
        description:
          'Para integrar o Pix do Sicredi ao seu e-commerce de forma eficiente, é fundamental que você possua uma conta jurídica ativa no Sicredi, onde a API do Pix esteja habilitada. Ao ativar a API, serão disponibilizados elementos essenciais como o client_id e client_secret, bem como sua chave de desenvolvimento, você também terá acesso à sua chave privada e certificado. Essas informações são cruciais para garantir a segurança e o funcionamento adequado da integração.',
      },
      {
        title: 'Relação entre campos',
        subtitle:
          'Como os campos da integração se relacionam com meus dados do pix Sicredi?',
        description:
          'Campos:<ul> <li> Usuario = client_id <li/> <li>Senha = client_secret<li/><li>Chave de Desenvolvimento = developer_application_key<li/><ul/>',
      },
    ],
  },
  pagopar: {
    title: 'Pagopar',
    steps: [
      {
        title: 'Integração Pagopar',
        subtitle: 'Como integrar o Pagopar ao seu e-commerce?',
        description:
          'Para integrar o Pagopar ao seu e-commerce, é necessário possuir uma conta na plataforma. A partir disso, você poderá obter sua chave privada e publica, essencial para realizar a integração. Com essas informações em mãos, basta acessar a seção de integração e preencher os campos solicitados.',
      },
      {
        title: 'Relação entre campos',
        subtitle:
          'Como os campos da integração se relacionam com meus dados da Pagopar',
        description:
          'Campos:<ul> <li> Chave de Pagamento = Public_key <li/> <li> Chave Privada = Private_key <li/><ul/>',
      },
    ],
  },
  asaas: {
    title: 'Asaas',
    steps: [
      {
        title: 'Integração Asaas',
        subtitle: 'Como integrar o Asaas ao seu e-commerce?',
        description:
          'Para integrar a Asaas ao seu e-commerce, é necessário possuir uma conta na plataforma. A partir disso, você poderá obter sua chave de API, essencial para realizar a integração. Com a chave de API em mãos, basta acessar a seção de integração e preencher os campos solicitados.',
      },
      {
        title: 'Benefícios da Asaas',
        subtitle: 'O que a Asaas oferece?',
        description:
          'O principal benefício do Asaas é proporcionar diversas opções de pagamento, permitindo que dentro do e-commerce seja viável realizar transações tanto com cartão de crédito quanto de débito, além da geração de boletos bancários para pagamento.',
      },
    ],
  },
}

export const getTutorial = (name) => {
  return integrationsTutorialTexts[name]
}
