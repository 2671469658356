import service from '@/services/billing/PixService'

export const pixModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    currencyDefault: 'R$',
  },
  mutations: {},
  actions: {
    async getPayment(context, payload) {
      const response = await service.getPayment(payload)
      return response
    },
    async sendPaymentConfirmation(context, payload) {
      return await service.sendPaymentConfirmation(payload)
    },
  },
  getters: {
    currencyDefault: (state) => {
      return state.currencyDefault
    },

    exchangeRate: (state, getters, rootState, rootGetters) => {
      const currencies = rootGetters['systemModule/getSystemCurrencies']
      const moedaPadrao = rootGetters['systemModule/getMainCurrency']

      const exchanges = rootGetters['exchangeModule/exchangeRate']

      const exchangeMoedaPadrao = exchanges.find(
        (exchange) => exchange.moeda == moedaPadrao
      ) // 7000

      const exchangePix = exchanges.find(
        (exchange) => exchange.cifrao == state.currencyDefault
      ) // 4,60

      const currencyMoedaPadrao = currencies.find(
        (currency) => currency.index == moedaPadrao
      )

      if (moedaPadrao == 0) return exchangePix.valor
      else {
        let amount = {
          ...exchangeMoedaPadrao,
        }
        if (currencyMoedaPadrao.sinal == '/')
          amount.valor = exchangeMoedaPadrao.valor * exchangePix.valor
        else amount.valor = exchangeMoedaPadrao.valor / exchangePix.valor
        return amount
      }
    },

    exchangeOrder: (state, getters, rootState, rootGetters) => {
      const exchanges = rootGetters['exchangeModule/exchangeOrder']

      const exchangeOrder = exchanges?.find(
        (exchange) => exchange.cifrao == state.currencyDefault
      )
      return exchangeOrder?.valor ?? 0
    },
  },
}
