import Service from '@/services/Service'

class AsaasService extends Service {
  constructor() {
    super('ASAAS')
    this.resource = 'mbcommerce/billing/asaas'
  }

  createRequestUrl(query) {
    let requestUrl = `${this.resource}?`

    if (query) query = this.encodeQueryValues(query)

    if (query.todos)
      requestUrl += this.hasParameter(requestUrl)
        ? `&TODOS=${query.todos}`
        : `TODOS=${query.todos}`
    else
      requestUrl += this.hasParameter(requestUrl)
        ? `&page=${query.page}`
        : `page=${query.page}`

    if (query.filtro)
      requestUrl += this.hasParameter(requestUrl)
        ? `&filtro=${query.filtro}`
        : `filtro=${query.filtro}`
    return requestUrl
  }

  async getPayment(payload) {
    let requestUrl = `${this.resource}/${encodeURIComponent(payload)}`
    return await this.getAPI(requestUrl)
  }
}

export default new AsaasService()
